<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="isToFullScreen"
    :show-close="false"
    class="dialog-standard plan-edit"
    top="30px"
    visible
    width="1000px"
  >
    <section slot="title" class="dialog-header">
      <span class="dialog-title">查看融资方案组</span>
      <span class="right-icon">
        <i :class="[isToFullScreen ? 'el-icon-news' : 'el-icon-full-screen']" @click="setFullScreen" />
        <i class="el-icon-close" @click="handleCancel" />
      </span>
    </section>
    <section class="dialog-body">
      <el-form ref="formRef" :inline="true" :model="dataForm" label-width="150px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="店铺名称：">
              <span>{{ dataForm.companyName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="店铺绑定渠道：">
              <span>{{ dataForm.channelName ? dataForm.channelName : '直客' }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="客户资金方：" >
              <span>{{ dataForm.funderName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业绑定渠道：" >
              <span>{{ dataForm.channelName }}</span>
            </el-form-item>
          </el-col>
        </el-row> -->
        <!-- <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="首笔提款回溯天数：" >
              <span>T - {{ dataForm.firstTraceDays }}天</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="多笔提款回溯天数：" >
              <span>T - {{ dataForm.multipleTraceDays }}天</span>
            </el-form-item>
          </el-col>
        </el-row> -->
      </el-form>
      <div class="table-list">
        <!-- <p class="tips">
          注：对客利率上一档价格小于等于下一档，且不能低于对应档的渠道费率
        </p> -->
        <avue-crud ref="crud" :data="dataList" :option="option" :table-loading="isLoading">
          <template #channelRate="{ row }">
            {{ row.channelRate }}
          </template>
        </avue-crud>
      </div>
    </section>
    <section class="dialog-footer">
      <!-- <el-button type="primary" icon="el-icon-circle-check" v-loading="isSubmiting"
        @click="handleConfirm">保存</el-button> -->
      <el-button class="cancel" icon="el-icon-circle-close" @click="handleCancel">取消</el-button>
    </section>
  </el-dialog>
</template>

<script>
// done 我的任务-待办任务-审批-查看  展示对应计息方式费率
import { dealNull } from '@/filters/index';

export default {
  name: 'PlanEdit',
  props: {
    /** 列表当前操作行数据 */
    dataItem: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      isToFullScreen: false,
      isLoading: true,
      isSubmiting: false, // 防重复提交
      // 表单配置
      dataForm: {},
      // 列表配置
      dDayList: [
        {
          turnoverStartDay: '1',
          turnoverEndDay: '4',
          maxDay: '7'
        },
        {
          turnoverStartDay: '5',
          turnoverEndDay: '6',
          maxDay: '10'
        },
        {
          turnoverStartDay: '7',
          turnoverEndDay: '10',
          maxDay: '20'
        },
        {
          turnoverStartDay: '11',
          turnoverEndDay: '15',
          maxDay: '25'
        },
        {
          turnoverStartDay: '16',
          turnoverEndDay: '20',
          maxDay: '30'
        },
        {
          turnoverStartDay: '21',
          turnoverEndDay: '26',
          maxDay: '35'
        },
        {
          turnoverStartDay: '27',
          turnoverEndDay: '30',
          maxDay: '40'
        },
        {
          turnoverStartDay: '31',
          turnoverEndDay: '35',
          maxDay: '45'
        },
        {
          turnoverStartDay: '36',
          turnoverEndDay: '40',
          maxDay: '50'
        },
        {
          turnoverStartDay: '41',
          turnoverEndDay: '45',
          maxDay: '55'
        }
      ],
      // 列表配置
      option: {
        addBtn: false,
        refreshBtn: false,
        columnBtn: false,
        header: false,
        menu: false,
        border: false,
        stripe: true,
        align: 'left',
        searchShowBtn: false,
        column: [
          {
            label: '对客利率（单笔%）',
            prop: 'channelRate',
            width: 300
          },
          {
            label: '平均回款天数',
            prop: 'avePayDays',
            width: 300
          },
          {
            label: '最大用款天数',
            prop: 'maxDay',
            width: 200
          },
          {
            label: '渠道费率',
            prop: 'channelRatePer'
          }
        ]
      },
      dataList: []
    };
  },
  watch: {},
  created() {
    console.log(this.dataItem);
  },
  mounted() {
    this.isLoading = true;
    this.getCustRates();
  },
  methods: {
    /** 获取渠道数据 */
    getCustRates() {
      this.doPost('bium-portal/controller/fin-ops/ratePlan/getRatePlanDetail', {
        storeId: this.dataItem.storeId
      }).then(({ data }) => {
        if (data && data.result) {
          this.dataForm = data.result;
          if (this.dataForm.billingMethod == 'Annualized') {
            this.option.column[0].label = '对客利率（年化%）';
          } else {
            this.option.column[0].label = '对客利率（单笔%）';
          }
          this.dataList = this.dealResData(data.result.custRateDetailDTOList);
        }
        console.log(this.dataList);
        console.log('this.dataList:', this.dataList);
        this.isLoading = false;
      });
    },
    /** 关闭 */
    handleCancel() {
      this.$options.cancel && this.$options.cancel();
    },
    /** 全屏设置 */
    setFullScreen() {
      this.isToFullScreen = !this.isToFullScreen;
    },

    /** 预处理接口返回数据 */
    dealResData(resData) {
      if (resData && resData.length) {
        return resData.map((item, idx) => {
          return {
            channelRate: Number(item.customerRate * 100).toFixed(4),
            avePayDays: `${dealNull(this.dDayList[idx].turnoverStartDay)}~${dealNull(
              this.dDayList[idx].turnoverEndDay,
            )}天`,
            maxDay: this.dDayList[idx].maxDay,
            channelRatePer: Number(dealNull(item.channelRate * 100, 0)).toFixed(4) + '%'
          };
        });
      }
      return [];
    }
  }
};
</script>

<style lang="scss" scoped>
.plan-edit {
  .table-list {
    .tips {
      margin-bottom: 10px;
      padding: 2px 10px;
      background-color: #ecf5ff;
      border: 1px solid #d9ecff;
      border-radius: 4px;
      color: #e6a23c;
    }
  }
}
</style>
