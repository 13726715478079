var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog-standard plan-edit",
      attrs: {
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        fullscreen: _vm.isToFullScreen,
        "show-close": false,
        top: "30px",
        visible: "",
        width: "1000px",
      },
    },
    [
      _c(
        "section",
        {
          staticClass: "dialog-header",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("span", { staticClass: "dialog-title" }, [
            _vm._v("查看融资方案组"),
          ]),
          _c("span", { staticClass: "right-icon" }, [
            _c("i", {
              class: [
                _vm.isToFullScreen ? "el-icon-news" : "el-icon-full-screen",
              ],
              on: { click: _vm.setFullScreen },
            }),
            _c("i", {
              staticClass: "el-icon-close",
              on: { click: _vm.handleCancel },
            }),
          ]),
        ]
      ),
      _c(
        "section",
        { staticClass: "dialog-body" },
        [
          _c(
            "el-form",
            {
              ref: "formRef",
              attrs: {
                inline: true,
                model: _vm.dataForm,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "店铺名称：" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.dataForm.companyName))]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "店铺绑定渠道：" } },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.dataForm.channelName
                                  ? _vm.dataForm.channelName
                                  : "直客"
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table-list" },
            [
              _c("avue-crud", {
                ref: "crud",
                attrs: {
                  data: _vm.dataList,
                  option: _vm.option,
                  "table-loading": _vm.isLoading,
                },
                scopedSlots: _vm._u([
                  {
                    key: "channelRate",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(row.channelRate) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "cancel",
              attrs: { icon: "el-icon-circle-close" },
              on: { click: _vm.handleCancel },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }